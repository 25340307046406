import * as packageJson from '../../../../package.json';

const { version } = packageJson;

export const environment = {
  production: true,
  env: 'dev',
  version,
  host: 'https://app-review-wtf-58-jh8rzi.parashift.io',
  build_timestamp: `${new Date().getTime()}`,
  id_node: 'https://id.dev.parashift.io',
  endpoints: {
    version: 'v1',
    individual_extraction_version: 'app',
    api: 'https://api-dev.parashift.io',
    id: 'https://id-api.dev.parashift.io',
    individual_extraction: 'https://individual-extraction.dev.parashift.io',
    webhooks: 'https://webhooks.dev.parashift.io'
  },
  sentry: 'https://19a786daef15944f6b479e114701d6f7@o121143.ingest.us.sentry.io/4507616716324864',
  enable_sentry: false,
  log: true,
  list_default_page_size: 20,
  userflow: {
    token: 'ct_qwyhqxqedrfzfocohn5fxpghxy',
    onboarding_flow_id: '0ddab94a-e7b1-4080-98a1-7ae301e47a49'
  },
  reset: {
    filters: true
  },
  assets_prefix: '',
  active_storage_attachment_poll_minutes: 30,
  llm_field_extractor_id: 4732,
};
